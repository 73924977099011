type LocalStorageItem =
  | "language"
  | "codeVerifier"
  | "token"
  | "tokenExp"
  | "refreshToken"
  | "activeOrg";

export const localStorageGet = (key: LocalStorageItem) =>
  localStorage.getItem(key);
export const localStorageSet = (key: LocalStorageItem, value: string) =>
  localStorage.setItem(key, value);
export const localStorageRemove = (key: LocalStorageItem) =>
  localStorage.removeItem(key);
