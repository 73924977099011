export class RequestUtils {
  static replaceEmptyWithNull(object: any) {
    for (let key in object) {
      if (object[key] === '') {
        object[key] = undefined;
      }
    }
    return object;
  }
}
